<template>
  <div class="ccding">
    <!-- ccding首页 -->
    <div v-if="isCcDing=='1'">
      <!-- 步骤条 -->
   <div class="stepBox" >
     <el-steps :active="stepActive" align-center >
      <el-step :title="stepItem.title" v-for="stepItem in stepList" :key="stepItem.num" class="itemBox" @click.native="changActive(stepItem.num)"></el-step>
    </el-steps>
   </div>
    <!-- 内容展示 -->
    <!-- installccApp组件中有接口轮询，使用v-if -->
    <installccApp v-if="stepActive===1" :stepActive.sync="stepActive" :isAllInstall.sync='isAllInstall'/>
    <!-- 授权绑定 -->
    <bindTable v-if="stepActive===2" />
    <!-- 数据同步 -->
    <synchronization v-if="stepActive===3" />
    </div>
    <!-- 为购买cc钉钉邀请页 -->
    <invitation v-if="isCcDing=='2'"/>
  </div>
</template>

<script>
import * as ccding from './api.js'
import  invitation from './invitation'
import installccApp from './components/installccApp'
import bindTable from './components/bindTable'
import synchronization from './components/synchronization'
export default {
  components:{
    installccApp,
    bindTable,
    synchronization,
    invitation
  },
    data(){
        return {
          // 是否全部安装成功，如果全部安装成功，再次进来不需要轮询接口，也不用自动跳转到第二步
          isAllInstall:false,
          // 是否购买ccding,0:是展示空白，1购买，2未购买
          isCcDing:'1',
          // 当前所在的步骤
          stepActive: 1,
          // 步骤条展示数据
          stepList:[{title:'安装应用',num:1},{title:'授权绑定',num:2},{title:'数据同步',num:3},]
        }
    },
    created(){
      // this.getCCding()
      // 查询钉钉是否安装成功
      this.getIsInstallDingDing()
    },
    methods:{
          /**
     * 查询钉钉是否安装
     */
    getIsInstallDingDing(){
      ccding.getIsInstallDingDing().then(res=>{
        let application=res.data?.application
        if(application){
          // 更新全部下载标识
          this.isAllInstall=application.account==='true'&&application.crm==='true'&&application.dashboard==='true'
         
        }
      })
    },
      /**
       * 查询是否购买cc钉钉
       * 
       */
      getCCding(){
        ccding.getIsBug().then(res=>{
          // 如果res.data有值就是购买了，没值就是未购买
          this.isCcDing=res.data?'1':'2'
          // 暂时先按购买
          // this.isCcDing='1'
          
        })
      },
      /**
       * changActive:步骤条切换
       * @param {String} val:需要切换到的步骤编号
       */
       changActive(val){
        if(val===this.stepActive)return;
        this.stepActive=val
       }
    }
}
</script>

<style lang="scss" scoped>
// #00BC68
.ccding{
  .stepBox{
    min-width: 950px;
    padding: 20px 200px;
    box-shadow: 0px 3px 10px 1px rgba(225,225,225,0.5000);
    ::v-deep .itemBox{
      margin-right: 60px !important;
      .el-step__icon{
        cursor: pointer;
        left: -30px;
        top: -10px;
      }
      .el-step__title{
        cursor: pointer;
        margin-right: 60px;
        font-size: 14px;
        line-height: 1;
      }
      // 经过的步骤
      .is-finish{
        color: #00BC68;
        .el-step__icon.is-text{
          border-color: #00BC68;
        }
        .el-step__line{
          border-color: #00BC68;
        }
      }
      // 下一步骤
      .el-step__head.is-process{
        color: #BBB;
        border-color: #BBB;
        
      }
      .el-step__title.is-process{
        color: #BBB;
        font-weight: 400;

      }
    }
  }
}
</style>