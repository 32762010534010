<template>
  <div class="invitationBox">
    <!-- 邀请您体验钉钉集成后台系统设置 -->
    <div class="titleBox">{{ $t("c1001") }}</div>
    <!-- 内容展示 -->
    <div class="textBox">
      <div v-for="guide in guideList" :key="guide.icon" class="itemBox" :class="{marginClass:guide.margin}">
        <div class="iconBox">
          <svg  class="icon" aria-hidden="true">
                <use :href="guide.icon"></use>
            </svg>
        </div>
        <div>{{guide.text}}</div>
      </div>
    </div>
    <!-- 如需使用CC钉请联系我们（XXX---XXXX） -->
    <div class="bottonBox"> 
      <!-- icon-phone -->
      <svg  class="icon" aria-hidden="true">
                <use href="#icon-phone"></use>
            </svg>
            <!-- 如需使用CC钉请联系我们（XXX---XXXX） -->
            {{$t("c1005")}}（XXX---XXXX）
      
      </div>
  </div>
</template>

<script>
/**
 * 新功能引导页，如果用户未购买cc钉服务，进入此页面
 */
export default {
    data(){
        return {
          // 展示内容
            guideList:[{
                    icon:'#icon-yindao-1',
                    text:this.$i18n.t("c1002")
                    // text:'CloudCC钉应用是基于CloudCC与钉钉平台打造的营销销售服务一体化解决方案。'
                },{
                    margin:true,
                    icon:'#icon-yindao-2',
                    text:this.$i18n.t("c1003")
                    // text:'CC钉通过连接钉钉的协同能力，帮助您更完整的进行私域流量运营，追踪、管理客户关系全生命周期，提升企业运营效率和盈利能力。'
                },{
                    icon:'#icon-yindao-3',
                    text:this.$i18n.t("c1004")
                    // text:'CC钉继承CloudCC平台强大的定制化能力，可以针对您个性化的业务需求，快速、灵活的配置扩展。'
                }]
        }
    },
    methods:{
       
    }
}
</script>

<style lang="scss" scoped>
.invitationBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15vh 10px 0;
  .titleBox{
    font-size: 18px;
    font-weight: bold;
  }
  .textBox{
    display: flex;
    justify-content: space-between;
    margin: 80px 0 100px;
    .marginClass{
      margin: 0 95px;
    }
    .itemBox{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 220px;
      .iconBox{
        padding-bottom: 32px;
        .icon{
          width: 48px;
          height: 48px;
        }
      }
      
    }
  }
  .bottonBox{
    font-size: 16px;
    .icon{
      margin-right:4px
    }
    
  }
}
</style>