<template>
  <div class="user-binding">
    <!-- tab组件面板切换 -->
    <el-tabs v-model="activeName" @tab-click="handleClick" class="user-tabs">
      <el-tab-pane v-for="activeItem in activeList" :key="activeItem.num" :label="activeItem.name" :name="activeItem.num"></el-tab-pane>
    </el-tabs>
    <div>
      <!-- 搜索条件 -->
      <div class="user-search">
        <div>
          <el-input
            size="mini"
            placeholder="列表内搜索"
            v-model="condition"
            class="input-with-select"
            @change="seachBoundUser"
          >
            <el-button slot="prepend" icon="el-icon-search"></el-button>
          </el-input>
          <el-button size="mini" @click="seachBoundUser">{{ $t('label.tabpage.searchz') }}</el-button>
        </div>
        <div>
          <div v-if="activeName == 'Unbound' && !editAllBtn">
            <el-button size="mini" type="primary" @click="autoMate"
              >{{ $t('front-epwechat-module-v1-auto-mate') }}</el-button
            >
            <el-button size="mini" type="primary" @click="editAllBtn = true"
              >{{ $t('lable.product.pricebooks.editall') }}</el-button
            >
          </div>
          <div v-else-if="activeName == 'Unbound' && editAllBtn">
            <el-button size="mini" type="primary" @click="savebatchBoundEPUser"
              >{{ $t('label.tabpage.save') }}</el-button
            >
            <el-button size="mini" type="primary" @click="cancelbatchBoundEPUser"
              >{{ $t('label.tabpage.cancel') }}</el-button
            >
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <wechat-table
      class="table_height"
        :heightTable="heightTable"
        sign="user"
        :page="page"
        :wechatdata="wechatdata"
        :showStar="true"
        :isShowOpration="true"
        :oprationBtnList="
          activeName == 'Unbound'
            ? !editAllBtn
              ? unboundBtn
              : []
            : boundBtn
        "
        :editAllBtn="editAllBtn"
        :editInner="editInner"
        :innerData="innerData"
        @AccountBinding="AccountBinding"
        @Save="Save"
        @cancel="cancel"
        @Rebind="Rebind"
        @Unbind="Unbind"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      ></wechat-table>
    </div>
    <!-- 解除绑定弹窗 -->
    <el-dialog :visible.sync="dialogUnbind" width="460px">
      <div slot="title" class="dialog-title">
        <i class="el-icon-warning-outline" style="color: #e6a23c"></i>
        <span class="title-text">&nbsp;&nbsp;{{ $t('label.prompt') }}</span>
      </div>
      <div>{{ $t('front-epwechat-module-v1-unbound-desc') }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogUnbind = false">{{ $t('label.tabpage.cancel') }}</el-button>
        <el-button size="mini" type="primary" @click="unboundEPUserData"
          >{{ $t('label.tabpage.ok') }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as ccding from "../api.js"
import * as EPWeChat from "../api.js"
import wechatTable from "./dingTable.vue"
export default {
  components: {
    wechatTable,
  },
  data() {
    return {
      // tab切换数据
      activeList:[{
        name:'未绑定角色',
        num:"Unbound"
      },{
        name:'已绑定角色',
        num:"2"
      },{
        name:'未绑定用户',
        num:"3"
      },{
        name:'已绑定用户',
        num:"4"
      }],
      // 当前展示的tab，默认第一项，值对应activeList中的num
      activeName: "Unbound",
      // 表格高度
      heightTable:'calc(100vh - 370px)',
      wechatdata: {
        tabledata:[], //数据
        tablekey:[] //表头
      },
      page: {
        pageNum: 1,
        pageSize: 20,
        total: 100,
      },
      
      condition: "", //按姓名、邮箱、手机号查找用户
      editAllBtn: false,//全部编辑
      editInner: false,//行内编辑
      innerData: {},//单行数据
      isShowOpration: true, //是否显示操作列
      // 操作列功能按钮
      unboundBtn: [
        { action: "AccountBinding", label: this.$i18n.t('front-epwechat-module-v1-account-binding'), type: "primary" },
      ],
      boundBtn: [
        { action: "Rebind", label: this.$i18n.t('front-epwechat-module-v1-again-binding'), type: "primary" },
        { action: "Unbind", label: this.$i18n.t('label.weixin.unbind'), type: "primary" },
      ],
      dialogUnbind: false, // 解除绑定弹窗
      rowData:"",//解绑该行数据
      currentBtntext: "",//账号绑定或重新绑定，用于提示成功信息
    };
  },
  created(){
    // 查询未绑定的角色
    this.getUnBindRole()
  },
  mounted() {
    // 查询未绑定的用户
    this.getNoBind()
  },
  methods: {
    /** 
     * tabs切换
     * @param {Object} tab:当前切换项
     * 
     */
    handleClick(tab={}) {
      this.editAllBtn = false;//防止在未绑定用户列表编辑全部，已绑定用户列表依旧是编辑状态。
      this.getBoundUserData(tab.name,this.page);
    },
    /**
     * tab切换后获取对应的数据
     * @param {String} type:搜索类型
     * @param {String} page:分页当前页数
     * @param {String} size:分页当前展示数据条数
     */
    getBoundUserData(){
    },
    /** 
     * 查询未绑定的角色
     */
    getUnBindRole() {
      let params={
        orgid:"",
        condition:"",
        page:"",
        count:""}
      ccding.getUnBindRole(params).then(()=>{
      })
    },
    /**
     * 查询未绑定用户
     */
    getNoBind(){
      let params={
        orgid:"",
        condition:"",
        page:"2",
        count:"5"}
      ccding.getUnbindUser(params).then(res=>{
        if(res.data){
          this.wechatdata=res.data
        }
      })
    },
    /** 账号绑定 */
    AccountBinding(row,btntext) {
      this.editInner = true
      this.innerData = row
      this.currentBtntext = btntext
    },
    /** 保存 */
    Save(row) {
      this.saveboundEPUser(row);
      this.editInner = false
    },
    /** 取消 */
    cancel(){
      this.seachBoundUser()
      this.editInner = false
    },
    /** 用户绑定企业微信 */
    
    saveboundEPUser(data) {
      EPWeChat.boundEPUser({
        loginname: data.loginname,
        cloudccuserid: data.cloudccuserid,
        openid: data.openid,
      }).then((res) => {
        if (10000 == res.returnCode) {
          this.seachBoundUser()
          this.$message.success(this.currentBtntext+'成功')
        }else{
          // 登陆失败或其他
          this.$message.warning(res.returnInfo)
        }
      });
    },
    /** 重新绑定 */
    Rebind(row,btntext) {
      this.editInner = true
      this.innerData = row
      this.currentBtntext = btntext
    },
    /** 解除绑定 */
    Unbind(row) {
      this.dialogUnbind = true;
      this.rowData = row;
    },
    /** 解绑企业微信用户 */
    unboundEPUserData(){
      let data = this.rowData;
      EPWeChat.unboundEPUser({
        userid: data.cloudccuserid,
        epuserid: data.openid,
      }).then((res) => {
        if (10000 == res.returnCode) {
          this.dialogUnbind = false;
          this.getBoundUserData('1',this.page)
          // 解绑成功
          this.$message.success(this.$i18n.t('c41'))
        }
      });
    },
    /** 自动匹配 */
    autoMate() {
      EPWeChat.autoBoundEPUser().then((res) => {
        if (10000 == res.returnCode) {
          this.getBoundUserData('0',this.page);
          // 自动匹配成功
          this.$message.success(this.$i18n.t('c108'))
        }
      });
    },
    /** 搜索 */
    seachBoundUser() {
      let status = this.activeName == "Unbound" ? "0" : "1";
      this.getBoundUserData(status,this.page);
    },
    
    handleSizeChange(val){
      this.page.pageSize = val
      this.seachBoundUser()
    },
    handleCurrentChange(val){
      this.page.pageNum = val
      this.seachBoundUser()
    },
    /** 取消批量保存 */
    cancelbatchBoundEPUser(){
      this.editAllBtn = false;
      this.getBoundUserData('0',this.page);
    },
    /** 批量绑定用户 */
    savebatchBoundEPUser() {
      let params = [];
      let data = this.wechatdata.tabledata;
      data.forEach(item => {
        if (item.openid != "") {
          let obj = {}
          obj['cloudccuserid'] = item.cloudccuserid
          obj['loginname'] = item.loginname
          obj['openid'] = item.openid
          params.push(obj)
        }
      });

      EPWeChat.batchBoundEPUser(params).then((res) => {
        if (10000 == res.returnCode) {   
          this.editAllBtn = false;
          let status = this.activeName == "Unbound" ? "0" : "1";
          this.getBoundUserData(status,this.page);
          // 编辑全部成功
          this.$message.success(this.$i18n.t('c174'))
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.user-binding {
  padding: 15px 10px;
  .user-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .input-with-select {
      width: 280px;
      margin-right: 10px;
    }
  }
}
.user-tabs{
  .is-active{
    color: #2d6cfc !important;
  }
  .el-tabs__active-bar{
    background-color: #2d6cfc !important;
  }
}
.table_height{
  height: calc(100vh - 370px);
  min-height: 200px;
  width: calc(100vw - 350px - 42px);
  min-width: 400px;
}
</style>