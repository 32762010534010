<template>
  <div class="synchronization">
            
    <div v-for="item in list" :key="item.type" class='synchronization-item'>
       
        <div class='synchronization-item-title'> {{item.title}}</div>
        <div class='synchronization-item-text'> {{item.text}}</div>
        <div class='synchronization-item-button'>
         <el-button type="primary" size="small">开启</el-button>
        </div>
    </div>
  </div>
</template>

<script>
/**
 * 数据同步
 */
export default {
    data(){
        return {
            // 数据同步
            list:[
                {title:'外部联系人同步',text:'外部联系人同步开启后，钉钉外部联系人数据将自动进入系统，方便随时查看外部联系人信息',type:'contacts'},
                {title:'事件同步',text:'事件开启后可与钉钉的日历双向同步',type:'event'},
                {title:'任务同步',text:'任务开启后可与钉钉的待办双向同步',type:'task'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.synchronization{
    display: flex;
    justify-content: space-around;
    .synchronization-item{
        width: 30%;
        min-width: 260px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #F9F9F9;
        border-radius: 8px 8px 8px 8px;
        margin-top: 20px;
        padding: 20px 40px;
        .synchronization-item-title{
            font-size: 16px;
            font-weight: bold;
            color: #080707;
        }
        .synchronization-item-text{
            color: #666666;
            padding: 10px 0 20px 0;
        }
        .synchronization-item-button{
            text-align: center;
        }

    }
}
</style>