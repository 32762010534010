import axios from "@/config/httpConfig"

// cc钉钉查询未绑定用户
export function getUnbindUser(params){
    return axios.post('/dingdingbusiness/getUnBindUser',params)
}
// cc钉钉查询未绑定角色
export function getUnBindRole(params){
    return axios.post('/dingdingbusiness/getUnBindRole',params)
}
// cc钉钉查询钉钉是否安装
export function getIsInstallDingDing(){
    return axios.get('/dingdingThirdConfig/getIsInstallDingDing')
}
// cc钉钉是否购买cc钉钉
export function getIsBug(){
    return axios.get('/dingdingThirdConfig/getIsBug')
}




// 绑定企业微信
export function saveEPWeChatSetUp(params){
    return axios.post('/epwechatsetup/saveEPWeChatSetUp',params)
}

// 查询企业微信绑定
export function getEPWeChatSetUp(params){
    return axios.get('/epwechatsetup/getEPWeChatSetUp',params)
}

// 查询企业微信是否已安装应用
export function isInstallEPWeChat(params){
    return axios.post('/epwechatsetup/isInstallEPWeChat',params)
}

// 解除绑定
export function unBoundEPWeChat(params){
    return axios.post('/epwechatsetup/unBoundEPWeChat',params)
}

// 保存企业微信同步设置信息
export function saveEPWeChatSyncSetUp(params){
    return axios.post('/epwechatsyncsetup/saveEPWeChatSyncSetUp',params)
}

// 查询企业微信同步设置信息
export function getEPWeChatSyncSetUp(params){
    return axios.post('/epwechatsyncsetup/getEPWeChatSyncSetUp',params)
}

// 获取回调URL
export function getCallBackurl(){
    return axios.get('/epwechatsetup/getCallBackurl')
}
// ---------------------角色和用户绑定------------------------------
// 角色自动绑定
export function autoBoundEPDept(){
    return axios.get('/epwechatbinding/autoBoundEPDept')
}

// 用户自动绑定
export function autoBoundEPUser(){
    return axios.get('/epwechatbinding/autoBoundEPUser')
}

// 查询用户绑定信息
export function getBoundUser(params){
    return axios.post('/epwechatbinding/getBoundUser',params)
}

// 获取未绑定的企业微信账号
export function getUnboundEPUser(params){
    return axios.get('/epwechatbinding/getUnboundEPUser',params)
}

// 解绑企业微信用户
export function unboundEPUser(params){
    return axios.post('/epwechatbinding/unboundEPUser',params)
}

// 用户绑定企业微信
export function boundEPUser(params){
    return axios.post('/epwechatbinding/boundEPUser',params)
}

// 批量绑定企业微信账户
export function batchBoundEPUser(params){
    return axios.post('/epwechatbinding/batchBoundEPUser',params)
}

// 获取已绑定角色
export function getboundDept(params){
    return axios.post('/epwechatbinding/getboundDept',params)
}

// 获取未绑定角色
export function getUnboundDept(params){
    return axios.post('/epwechatbinding/getUnboundDept',params)
}

// 绑定角色
export function boundEPDept(params){
    return axios.post('/epwechatbinding/boundEPDept',params)
}

// 批量绑定角色
export function batchBoundEPDept(params){
    return axios.post('/epwechatbinding/batchBoundEPDept',params)
}

// 角色解绑
export function unboundEPDept(params){
    return axios.post('/epwechatbinding/unboundEPDept',params)
}
// ---------------------------自建应用设置---------------------------
// 企业微信自建应用公有私有云用户判断
export function getEPWeChatUserAppVersion(){
    return axios.get('/epwechatselfsetup/getEPWeChatUserAppVersion')
}

// 查询企业微信自建应用设置信息
export function getEPWeChatSelfSetUp(params){
    return axios.post('/epwechatselfsetup/getEPWeChatSelfSetUp',params)
}

// 保存企业微信自建应用设置信息
export function saveEPWeChatSelfSetUp(params){
    return axios.post('/epwechatselfsetup/saveEPWeChatSelfSetUp',params)
}

// -----------------------企业微信联系人转联系人或潜在客户--------------------------
export function epWechatToContactAndLead(params){
    return axios.post('/epwechatsynccontact/epWechatToContactAndLead',params)
}
