<template>
  <div>
    <el-table
      :data="wechatdataProp.tabledata"
      border
      :header-cell-class-name="showStar ? star : ''"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      :height="heightTable"
    >
      <el-table-column
        v-for="(item, key) in wechatdataProp.tablekey"
        :key="key"
        :prop="item.value"
        :label="item.name"
      >
        <template slot-scope="scope">
          <el-input
            v-if="
              ((currentId == scope.row.cloudccuserid ||
                currentId == scope.row.cloudccdeptid) &&
                currentValue == scope.column.property) ||
              ((currentId == scope.row.cloudccuserid ||
                currentId == scope.row.cloudccdeptid) &&
                currentValue1 == scope.column.property) ||
              (editAllBtn && currentValue == scope.column.property) ||
              (editAllBtn &&
                (currentValue == scope.column.property ||
                  currentValue1 == scope.column.property))
            "
            v-model="scope.row[item.value]"
            size="mini"
          ></el-input>
          <div v-else>
            <span>{{ scope.row[item.value] }}</span>
            <svg
              class="icon editIconImg"
              aria-hidden="true"
              @click="editCell(item, scope.row, scope.column)"
              style="
                position: absolute;
                right: 10px;
                top: 20px;
                display: none;
                cursor: pointer;
                height: 12px;
              "
            >
              <use href="#icon-pen"></use>
            </svg>
          </div>
        </template>
      </el-table-column>
      <!-- 操作列 -->
      <el-table-column
        :label="$t('label.department.operation')"
        v-if="isShowOpration"
      >
        <template slot-scope="scope">
          <span
            v-if="
              currentId == scope.row['cloudccuserid'] ||
              currentId == scope.row['cloudccdeptid']
            "
          >
            <el-button size="small" type="text" @click="handleBtnSave(scope)">{{
              $t("label.tabpage.save")
            }}</el-button>
            <el-button size="small" type="text" @click="handleBtnCancel()">{{
              $t("label.tabpage.cancel")
            }}</el-button>
          </span>
          <span v-else v-for="(item) in oprationBtnList" :key="item.action">
            <el-button
              size="small"
              type="text"
              @click="handleOperationBtn(scope, item)"
            >
              {{ item.label }}
            </el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="bottom_page">
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageNum"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="page.pageSize"
        layout="prev, pager, next, sizes,jumper"
        :total="page.total">
      </el-pagination>
     
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 表格高
    heightTable:{
      type:[Number,String],
      default:'100%'
    },
    // 表格数据
    wechatdata: {
      type: Object,
      default: ()=> {},
    },
    // 分页数据
    page: {
      type: Object,
      default: () => {},
    },
    // 是否展示操作按钮
    isShowOpration: {
      type: Boolean,
      default: false,
    },
    // 操作按钮列表
    oprationBtnList: {
      type: Array,
      default: () => [],
    },
    // 显示星号
    showStar: {
      type: Boolean,
      default: false,
    },
    // 编辑全部按钮
    editAllBtn: {
      type: Boolean,
      default: false,
    },
    // 编辑行内
    editInner: {
      type: Boolean,
      default: false,
    },
    // 单行数据
    innerData: {
      type: Object,
      default: () => {},
    },
    // 标志
    sign: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      wechatdataProp: this.wechatdata,
      innerDataProp: this.innerData,
      currentValue1: "", //列-角色的时候用
      currentValue: "", //列
      currentId: "", // 行
    };
  },
  watch: {
    // 编辑全部
    editAllBtn(oldv) {
      if (true == oldv) {
        if (this.sign == "user") {
          this.currentValue = "openid";
        } else if (this.sign == "role") {
          this.currentValue = "epdeptid";
          this.currentValue1 = "epdeptname";
        }
      } else {
        this.currentId = "";
        this.currentValue = "";
        this.currentValue1 = "";
      }
    },
    // 编辑行内
    editInner(oldv) {
      if (true == oldv) {
        if (this.sign == "user") {
          this.currentId = this.innerDataProp.cloudccuserid;
          this.currentValue = "openid";
        } else if (this.sign == "role") {
          this.currentId = this.innerDataProp.cloudccdeptid; //行
          this.currentValue = "epdeptid";
          this.currentValue1 = "epdeptname";
        }
      }
    },
  },
  methods: {
    /** star函数中指定序列返回star类名 */
    star(obj) {
      if (
        obj.column.label == this.$i18n.t("label.eowechat.epdeptnamee") ||
        obj.column.label == this.$i18n.t("label.eowechat.epdeptid") ||
        obj.column.label == this.$i18n.t("label.eowechat.openid")
      ) {
        return "star";
      }
    },
    /** 操作按钮 */
    handleOperationBtn(scope, item) {
      if (item.action == "Binding") {
        // 绑定
        this.$emit("Binding", scope.row, "绑定");
      } else if (item.action == "Rebind") {
        // 重新绑定
        this.$emit("Rebind", scope.row, "重新绑定");
      } else if (item.action == "Unbind") {
        // 解除绑定
        this.$emit("Unbind", scope.row);
      } else if (item.action == "AccountBinding") {
        // 账号绑定
        this.$emit("AccountBinding", scope.row, "账号绑定");
      }
    },
    /** 保存按钮 */
    handleBtnSave(scope) {
      this.currentId = "";
      this.currentValue1 = "";
      this.currentValue = "";
      this.$emit("Save", scope.row);
    },
    /** 取消按钮 */
    handleBtnCancel() {
      this.currentId = "";
      this.currentValue1 = "";
      this.currentValue = "";
      this.$emit("cancel");
    },
    // 编辑单元格
    editCell(item, row, column) {
      
      this.currentValue = column.property; //列
      if (this.sign == "user") {
        this.currentId = row.cloudccuserid;
      } else if (this.sign == "role") {
        this.currentId = row.cloudccdeptid; //行
      }
    },
    // 显示笔
    cellMouseEnter(row, column, cell) {
      // 判断是否开启内联编辑
      if(this.$store.state.userInfoObj.enableInlineEdit==='false') return;
      let attr = this.wechatdataProp.tablekey.filter((item) => {
        return item.value === column.property;
      });
      if (
        attr[0] && ((attr[0].name == this.$i18n.t("label.eowechat.openid")) ||
        (attr[0].name == this.$i18n.t("label.eowechat.epdeptnamee")) ||
        attr[0].name == this.$i18n.t("label.eowechat.epdeptid"))
      ) {
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display = "")
          : "";
      }
    },
    // 隐藏笔
    cellMouseLeave(row, column, cell) {
      // 判断是否开启内联编辑
      if(this.$store.state.userInfoObj.enableInlineEdit==='false') return;
      cell.getElementsByClassName("editIconImg")[0]
        ? (cell.getElementsByClassName("editIconImg")[0].style.display = "none")
        : "";
    },
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>
<style lang="scss" scoped>
// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }
  tr th {
    background: #fafaf9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  th .cell {
    padding-right: 14px;
    .el-checkbox {
      margin-left: 0;
    }
  }
}
.bottom_page {
  text-align: right;
}
/* 表头加星号 */
.el-table th.star > .cell:before {
  content: "*";
  color: #ff1818;
}
</style>