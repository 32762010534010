<template>
  <div class="installApp">
    <div class="titleBox">
      CC钉集成绑定设置必须为CC钉的管理员，且开通CC钉开发者后台账号
    </div>
    <div class="textBox">
      Cloudcc提供集成CC钉的能力，结合钉钉强大的功能与轻量化的H5版本Cloudcc系统，帮助您便捷的管理客户关系，运营私域流量，提升企业营收能力。
    </div>
    <!-- 分割线 -->
    <div class="dividerBox"></div>
    <!-- 二维码title -->
    <div class="titleBox boldBox">请您先用钉钉扫码创建应用程序</div>
    <!-- 二维码 -->
    <div class="installImgBox">
      <div class="imgBox" v-for="codeItem in codeList" :key="codeItem.id">
        <!-- 二维码 -->
        <div :id="codeItem.id" class="qrcodeBox">
            <!-- 遮罩层 -->
             <div class="dialogBox" v-show="codeItem.isfinish">
                <svg  class="icon" aria-hidden="true">
                <use href="#icon-gong-1-finish"></use>
            </svg>
             </div>
        </div>
       
        <!-- 文本 -->
        <div class="textCodeBox">{{ codeItem.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 安装cc钉钉
 */
import QRCode from "qrcodejs2";
import * as ccding from '../api'
export default {
  props:{
    // 当前步骤数
    stepActive:{
      type: [Number,String]
    },
    // 是否全部下载，全部下载不进行轮询获取安装接口
    isAllInstall: {
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      // 定时器
      setIntervalVal:null,
      // 二维码数据
      codeList: [
        { url: "https://www.baidu.com/", id: "account", text: "客户应用程序",isfinish:true },
        {
          url: "https://fanyi.baidu.com/#en/zh/",
          id: "crm",
          text: "CRM应用程序",
          isfinish:false
        },
        {
          url: "https://blog.csdn.net/LingHuzh/article/details/123482135",
          id: "dashboard",
          text: "仪表板应用程序",
          isfinish:false
        },
      ],
    };
  },
  created(){
    // 查询下载情况
    !this.isAllInstall&&this.getIsInstallDingDing(true)
  },
  mounted() {
    // 回显二维码
    this.createCode();
    // 轮询查询钉钉是否安装接口
    this.setSetInterval()
  },
  beforeDestroy(){
    // 清除轮询定时器
    clearInterval(this.setIntervalVal)
  },
  methods: {
    /**
     * 轮询查询钉钉是否安装接口
     */
    setSetInterval(){
      // 如果所有应用没有全部安装，开始轮询
      if(!this.isAllInstall){
        this.setIntervalVal=setInterval(() => {
          this.getIsInstallDingDing()
        }, 10000);
      }
    },
    /**
     * 查询钉钉是否安装
     */
    getIsInstallDingDing(){
      ccding.getIsInstallDingDing().then(res=>{
        let application=res.data?.application
        if(application){
          // 更新是否下载
          this.$set(this.codeList[0],'isfinish',application.account==='true')
          this.$set(this.codeList[1],'isfinish',application.crm==='true')
          this.$set(this.codeList[2],'isfinish',application.dashboard==='true')
          // 更新全部下载标识
          let isAllInstall=application.account==='true'&&application.crm==='true'&&application.dashboard==='true'
          this.$emit('update:isAllInstall',isAllInstall)
          if(isAllInstall){
            // 清除轮询定时器
            clearInterval(this.setIntervalVal)
            // 前往下一步
            this.$emit('update:stepActive',2)
          }
        }
      })
    },
    /**
     * 生成二维码
     */
    createCode() {
      this.codeList.forEach((item) => {
        new QRCode(item.id, {
          width: 165,
          height: 165,
          text: item.url,
          backgroound: "#f0f",
          foreground: "#ff0",
          correctLevel: 0,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.installApp {
  padding: 20px;
  .titleBox {
    font-size: 18px;
    color: #333333;
  }
  .textBox {
    padding: 8px 0 20px 0;
    color: #999999;
  }
  .boldBox {
    font-weight: bold;
  }
  .dividerBox {
    margin-bottom: 30px;
    height: 0px;
    opacity: 0.1;
    border: 1px solid #000000;
  }
  .installImgBox {
    display: flex;
    justify-content: space-between;
    padding: 60px 100px 0;
    .imgBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .qrcodeBox{
        position: relative;
      }
      .dialogBox{
        width: 165px;
        height: 165px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.24);
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
            width: 40px;
            height: 40px;
        }
      }
      .textCodeBox {
        padding-top: 20px;
        color: #666666;
      }
    }
  }
}
</style>